@use 'mixins';

.ForgotPassword {
    display: block;
    position: relative;
    z-index: 255;

    h1 {
        @extend %heading-large;
        font-size: 1.6em;
        text-align: center;
    }

    p {
        text-align: center;
    }

    .sign-up-success {
        background-color: inherit;
        padding: 0;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: rgb(var(--invertedBackground));
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 27.75em;
        padding: 0 var(--standardPadding) var(--largePadding) var(--standardPadding);
        margin: 0 auto;
        margin-bottom: var(--hugePadding);
        border-radius: var(--standardPadding);
        background-color: rgb(var(--background));
    }

    .container {
        max-width: 21.375em;
    }

    .content {
        h1 {
            font-size: 1.25em;
        }

        padding: var(--standardPadding) var(--standardPadding) var(--largePadding) var(--standardPadding);
        text-align: center;
        background-color: rgb(var(--sectionBack));
        border-radius: var(--standardRadius);

        :global(.check-icon) {
            width: var(--hugePadding);
            height: var(--hugePadding);
            color: rgb(var(--successColor));
        }
    }

    .resend-email {
        display: flex;
        flex-direction: column;
        padding-top: var(--largePadding);
        text-align: center;
        line-height: 1.5em;

        button {
            margin-left: var(--tinyPadding);
            padding: 0;
            color: rgb(var(--secondaryTextColor));
            text-decoration: underline;
            font-size: inherit;
            border: none;

            &:hover {
                background: none;
            }
        }
    }

    .form-error {
        padding: var(--standardPadding);
        margin-top: var(--standardPadding);
        border-radius: var(--standardRadius);
        background-color: rgba(var(--negativeColor), 0.1);
        color: rgb(var(--errorColor));
        line-height: var(--caption-small-line-height);
    }

    .error {
        color: rgb(var(--errorColor));
    }

    .form-success {
        padding: var(--standardPadding);
        margin-top: var(--standardPadding);
        border-radius: var(--standardRadius);
        background-color: rgba(var(--positiveColor), 0.1);
        color: rgb(var(--successColor));
        line-height: var(--caption-small-line-height);
    }

    @include mixins.responsive(m, below) {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        &::before {
            background-color: rgb(var(--background));
        }

        .wrapper {
            margin-bottom: 0;
        }
    }
}
